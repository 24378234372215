<template>
  <div>
      <b-container>
        <div class="row mt-3">
        <div v-if="eventinfo.live" class="col-sm-6 d-flex align-items-center">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe style="width: 100%; height: 100%;" src="https://stream.swissliveauction.ch:5443/WebRTCAppEE/play.html?name=368302377972371162025408" frameborder="0" allowfullscreen></iframe>
            <!-- <iframe style="width: 100%; height: 100%;" src="https://www.youtube.com/embed/0rmAGa6qVWE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  -->
          </div>
        </div>
        <div v-else class="col-sm-6 d-flex align-items-center">
          <div class="text-center w-100">
            {{$t('The live stream is not yet available.')}}
          </div>
        </div>
        <div class="mt-3 col-sm-6">
          <auctions-card :live="eventinfo.live"/>
        </div>
        </div>
        <div class="mt-3">
          <h6>{{$t('Catalog:')}}</h6>
          <div class="d-flex w-100 py-4" v-if="isloading">
            <b-spinner class="mx-auto"></b-spinner>
          </div>
          <div v-else>
            <carousel :perPage="1" class="mt-4" :paginationEnabled="false" :navigateTo="currendCow - 1" @page-change="changeCow" :navigationEnabled="!ismobile">
              <slide v-for="cow,id in allCows" :key="id">
                <swissgenetics-card v-if="eventinfo.swissgenetics" :id="cow._id" :isActive="currendCow == id + 1"></swissgenetics-card>
                <mutterkuh-s-card v-else-if="eventinfo.mutterkuh_weiblich" :id="cow._id" :isActive="currendCow == id + 1" /> 
                <mutterkuh-card v-else-if="eventinfo.mutterkuh" :id="cow._id" :isActive="currendCow == id + 1" />
                <cow-card v-else :id="cow._id" :isActive="currendCow == id + 1"/>
              </slide>
            </carousel>
            <b-pagination :range-before="5" v-model="currendCow" size="m" :total-rows="allCows.length" :per-page="1" align="center" class="mt-4">

                <span slot="page" slot-scope="{page, active }">
                  <b class="text-small" v-if="active">Kat. Nr: {{ allCows[page-1].katalognr}}</b>
                  <span class="text-small" v-else>Kat. Nr: {{ allCows[page-1].katalognr }}</span>
                </span>
            </b-pagination>
          </div>
        </div>
        <div class="mt-5">
          <puclic-card />
        </div>
      </b-container>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import CowCard from './sub_Event/Cow-Card.vue';
import AuctionsCard from './sub_Event/Auctions-Card.vue';
import PuclicCard from './sub_Event/Puclic-Card.vue';
import Auction from '@/states/auction.js';
import MutterkuhCard from './sub_Event/Mutterkuh-Card.vue';
import MutterkuhSCard from './sub_Event/Mutterkuh-SCard.vue';
import SwissgeneticsCard from './sub_Event/Swissgenetics-Card.vue';
export default {
  data() {
    return {
      msg: '',
      eventinfo: {},
      allCows: [{katalognr: 0}],
      currendCow: 1,
      catalognr: 0,
      isloading: true
    }
  },
  name: 'Event',
  components: {
    Carousel,
    Slide,
    CowCard,
    AuctionsCard,
    PuclicCard,
    MutterkuhCard,
    MutterkuhSCard,
    SwissgeneticsCard
    },
  mounted(){
    this.event()
    this.katalog();
    window.scrollTo(0, 0)
  },
  i18n: {
    messages: {
      de: {
          'The live stream is not yet available.': 'Der Live-Stream ist noch nicht verfügbar.',
          'Catalog:': 'Katalog:'
          
      },
      fr: {
          'The live stream is not yet available.': 'La diffusion en direct n\'est pas encore disponible.',
          'Catalog:': 'Catalogue:'
      }
    }
  },
  methods: {
    async katalog(){
      try {
        const response = await Auction.katalog(this.$route.params.eventID);
        this.allCows = response
        this.isloading = false
      } catch(error){
        this.msg = error
        console.log(this.msg)
        this.isloading = false
      }
    },
    async event(){
      try {
        const response = await Auction.event(this.$route.params.eventID);
        this.eventinfo = response
      } catch(error){
        this.msg = error
        console.log(this.msg)
      }
    },
    changeCow(page){
      this.currendCow = page + 1;
    }

  },
  computed: {
    site(){
        if (process.env.VUE_APP_SITE == 'Mutterkuh')
          return true
          return false
    },
    ismobile(){
      if (window.innerWidth > 960){
        return false
      }
      return true
    }
  }
  
}
</script>

<style style="scss" scoped>
.text-small {
  font-size: 10pt;
  white-space: nowrap;
}
</style>
