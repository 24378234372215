import { render, staticRenderFns } from "./Auctions-Card.vue?vue&type=template&id=20765ff0&scoped=true&"
import script from "./Auctions-Card.vue?vue&type=script&lang=js&"
export * from "./Auctions-Card.vue?vue&type=script&lang=js&"
import style0 from "./Auctions-Card.vue?vue&type=style&index=0&id=20765ff0&style=scss&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20765ff0",
  null
  
)

export default component.exports